import React from "react"
import { Link } from 'gatsby'
import { window } from 'browser-monads'
import "./nav.css"
import githubLogo from '../../images/github-512.png'
import linkedinLogo from '../../images/linkedin-512.png'

const Nav = () => (
  <nav>
    <div className="nav__items">
      <a className="nav__item--left" href="/" alt="Home Link">
        {"<Ross Perkel/>"}
      </a>
      <Link
        className={
          window.location.href.indexOf("blog") > 0 ||
          window.location.href.indexOf("category") > 0
            ? "nav__item--link active"
            : " nav__item--link"
        }
        href=""
        alt="Blog"
        to="/blog"
      >
        Blog
      </Link>

      <Link
        className={
          window.location.href.indexOf("resume") > 0
            ? "nav__item--link active"
            : " nav__item--link"
        }
        href=""
        alt="Resume"
        to="/resume"
      >
        Resume
      </Link>

      <Link
        className={
          window.location.href.indexOf("contact") > 0
            ? "nav__item--link active"
            : " nav__item--link"
        }
        href=""
        alt="Contact"
        to="/contact"
      >
        Contact
      </Link>
      <a
        className={
          window.location.href.indexOf("github") > 0
            ? "nav__item--link active"
            : " nav__item--link"
        }
        href="https://github.com/AvalancheOfFox"
        alt="github"
        to="https://github.com/AvalancheOfFox"
      >
        <img src={githubLogo} />
      </a>
      <a
        className={
          window.location.href.indexOf("linkedin") > 0
            ? "nav__item--link active"
            : " nav__item--link"
        }
        href="https://www.linkedin.com/in/rossmichaelperkel/"
        alt="linkedin"
        to="https://www.linkedin.com/in/rossmichaelperkel/"
      >
        <img src={linkedinLogo} />
      </a>
    </div>
  </nav>
)

export default Nav